.display-content{
    width: 100%;
}

.display-content-header{
    background-color: #3535d3;
    height: 50px;
    color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.display-content-list{
    margin: 20px;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
}