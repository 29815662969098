.navbar-item {
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}
  
.navbar-item > .navbar-item-text {
  align-items: center;
  background-color: rgba(0, 0, 128);
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  position: absolute;
}

.navbar-item > .navbar-item-list {
  background-color: rgba(0, 0, 128);
  display: none;
  max-height: 200px;
  overflow-y: scroll;
  position: absolute;
  top: 100%;
  width: 100%;
}

.navbar-item.clicked > .navbar-item-list {
  display: block;
  cursor: pointer;
}

.navbar-item > .navbar-item-list > .navbar-item-scroller {
  display: block;
  flex-direction: column;
  height: auto;
  width: 100%;
}

.navbar-item > .navbar-item-list > .navbar-item-scroller > .navbar-item-element {
  align-items: center;
  display: flex;
  height: 30px;
  width: 100%;
  justify-content: center;

  pointer-events: auto;  /* Ensure the element can receive pointer events */
  cursor: pointer;
}

