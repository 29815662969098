.card-box{
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    max-height: calc(100vh - 100px);  /* Set a max height, adjusting for the navbar */
    overflow-y: auto;
}

.card-item{
    margin: 10px;
    border: solid #0733c5 2px;
    border-radius: 10px;
    padding: 5px;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-img{
    width: 160px;
    height: 250px;
}


@media (max-width: 600px){
    .card-box{
        justify-content: center;
    }
}